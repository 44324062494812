import React from "react";
import "./Home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Mousewheel, Scrollbar } from "swiper";
import ToomarCart from "../Utils/ToomarCart";
import { useMediaQuery, useTheme } from "@mui/material";
// install Swiper modules
SwiperCore.use([Navigation, Mousewheel, Scrollbar]);

function BestSelling({ data, ...props }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  return (
    <div className={"bestSellingContainer"}>
      <div>
        <p>پرفروش‌ترین‌های</p>
        <p>طومار</p>
      </div>
      <div>
        <Swiper
          cssMode={true}
          slidesPerView={isMobile ? 1 : 3}
          loop={true}
          navigation={true}
          scrollbar={{ hide: true }}
          mousewheel={true}
          className={"toomarBestSelling"}
        >
          {data.map((item) => (
            <SwiperSlide>
              <ToomarCart value={item} inMainPage={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default BestSelling;
