import React, { useEffect } from "react";
import "./Utils.scss";
import { priceFormat } from "./PriceFormat";
import { connect } from "react-redux";
import { setFavoriteProducts } from "../../redux/actions";
import { useState } from "react";

function ToomarCart({ value, isSuggest, inMainPage, ...props }) {
  const [isFavorite, setIsFavorite] = useState(false);
  useEffect(() => {
    if (props.favoriteProducts && props.favoriteProducts.length > 0) {
      for (let i = 0; i <= props.favoriteProducts?.length; i++) {
        if (value.id === props.favoriteProducts[i]?.id) {
          setIsFavorite(true);
        }
      }
    }
  }, []);
  // const handleIsFavorite = () => {
  //     if(props.favoriteProducts && props.favoriteProducts.length > 0) {
  //         for(let i = 0; i <= props.favoriteProducts?.length; i++){
  //             if(value.id === props.favoriteProducts[i]?.id) {
  //             }
  //         }
  //     }
  //     else {
  //         setFavoriteProducts([...props.favoriteProducts, value]);
  //         setIsFavorite(true)
  //     }
  // }
  return (
    <a
      href={`/product/${value?.id}`}
      style={{ height: "380px" }}
      target="_blank"
    >
      <div className={"toomarCartContainer"}>
        <img src={value?.cover} alt={"کارت عروسی"} />
        <div className="overflowTitle">
          <p style={inMainPage === true ? { fontSize: 14 } : null}>
            {value?.title}
          </p>
        </div>
        <div className={"footerCards"}>
          <div className="productCode">
            <span style={{ color: "gray" }}>{value.code}</span>
          </div>
          <div className={"price"}>
            <span>تومان</span>
            <span>
              {value?.discount != 0
                ? priceFormat(value?.price * ((100 - value?.discount) / 100))
                : priceFormat(value?.price)}
            </span>
            {value?.discount != 0 ? (
              <span>{priceFormat(value?.price)}</span>
            ) : null}
          </div>
        </div>
        {value?.discount != 0 && isSuggest != true ? (
          <div
            className={"offPercent"}
            style={{
              background: value?.discount_label
                ? value.discount_color === 1
                  ? "black"
                  : "#e45803"
                : "#e45803",
            }}
          >
            {value.discount}%
          </div>
        ) : null}
        {value?.discount_label && (
          <div
            className="discountLabel"
            style={{
              backgroundColor: value?.discount_color === 1 ? "black" : "white",
              color: value?.discount_color === 1 ? "white" : "black",
              right:
                value?.discount != 0 && isSuggest != true
                  ? "54px"
                  : isSuggest == true
                  ? "140px"
                  : "0px",
              borderRadius:
                value?.discount != 0 || isSuggest ? "0px" : "0px 8px 0px 0px",
              width: "auto",
            }}
          >
            {value?.discount_label}
          </div>
        )}
        {isSuggest == true ? (
          <div className={"suggestContainer"}>
            <p>پیشنهاد شگفت انگیز</p>
          </div>
        ) : null}
        <div className={"favoriteContainer"}>
          <img
            src={require(isFavorite
              ? "../../assets/favorite.png"
              : "../../assets/non_favorite.png")}
          />
        </div>
      </div>
    </a>
  );
}

const mapStateToProps = (state) => ({
  favoriteProducts: state.counter.favoriteProducts,
});

export default connect(mapStateToProps, { setFavoriteProducts })(ToomarCart);
