import React, { useEffect } from "react";
import "./Favorite.scss";
import { connect } from "react-redux";
import { setFavoriteProducts } from "../../redux/actions";
import { priceFormat } from "../Utils/PriceFormat";

function FavoriteCard({ value, onDeleteFromFav }) {
  return (
    // <a style={{height: "380px"}}>
    <div className={"favCardContainer"}>
      <div
        className={"favoriteContainer"}
        onClick={() => onDeleteFromFav(value)}
      >
        <img src={require("../../assets/favorite.png")} />
      </div>
      <a href={`/product/${value?.id}`} target="_blank">
        <img
          src={
            value.cover
              ? value.cover
              : value?.images[value.images.length - 1].image
          }
          alt={"کارت عروسی"}
        />
        <div className="overflowTitle">
          <p>{value?.title}</p>
        </div>
        <div className={"footerCards"}>
          <div className="productCode">
            <span style={{ color: "gray" }}>{value.code}</span>
          </div>
          <div className={"price"}>
            <span>تومان</span>
            <span>
              {value?.discount != 0
                ? priceFormat(value?.price * ((100 - value?.discount) / 100))
                : priceFormat(value?.price)}
            </span>
            {value?.discount != 0 ? (
              <span>{priceFormat(value?.price)}</span>
            ) : null}
          </div>
        </div>
        {value?.discount != 0 ? (
          <div
            className={"offPercent"}
            style={{
              background: value?.discount_label
                ? value.discount_color === 1
                  ? "black"
                  : "#e45803"
                : "#e45803",
            }}
          >
            {value.discount}%
          </div>
        ) : null}
        {value?.discount_label && (
          <div
            className="discountLabel"
            style={{
              backgroundColor: value?.discount_color === 1 ? "black" : "white",
              color: value?.discount_color === 1 ? "white" : "black",
              right: value?.discount != 0 ? "54px" : "0px",
              borderRadius: value?.discount != 0 ? "0px" : "0px 8px 0px 0px",
              width: "auto",
            }}
          >
            {value?.discount_label}
          </div>
        )}
      </a>
      {/* {isSuggest == true ?
                <div className={'suggestContainer'}>
                    <p>
                        پیشنهاد شگفت انگیز
                    </p>
                </div>
                : null} 
                <div className={"favoriteContainer"}>
                    <img src={require(isFavorite ? "../../assets/favorite.png" : "../../assets/non_favorite.png")}/>
                </div> */}
    </div>
    // </a>
  );
}

const mapStateToProps = (state) => ({
  favoriteProducts: state.counter.favoriteProducts,
});

export default connect(mapStateToProps, { setFavoriteProducts })(FavoriteCard);
